import { EnvironmentProviders, Provider } from '@angular/core';
import { forceBackToCurrentLocation } from '@bcf-v2-configurators/tokens/cashier/mobile-web-twa/force-back-to-current-location';
import { FORCE_BACK_TO_CURRENT_LOCATION } from '@bcf-v2-configurators/tokens/cashier/token';
import { provideVanillaTsTokens } from '@bcf-v2-shared/misc/vanilla-ts-providers/provide-vanilla-ts-tokens';
import { cookieConsentDisabled } from '@bcf-vanilla-ts-v1-configurators/tokens/cookies/projects/casino/lvbetlv/mobile-twa-cookie-consent-disabled';
import { COOKIE_CONSENT_DISABLED } from '@bcf-vanilla-ts-v1-configurators/tokens/cookies/token';
import { registerDiToken } from '@bcf-vanilla-ts-v1-shared/di/di-token';

export const settingsProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: FORCE_BACK_TO_CURRENT_LOCATION,
    useValue: forceBackToCurrentLocation
  },
  provideVanillaTsTokens(() => {
    registerDiToken(COOKIE_CONSENT_DISABLED, () => cookieConsentDisabled);
  })
];
